import { render, staticRenderFns } from "./Alert.vue?vue&type=template&id=19dce2bc&scoped=true"
import script from "./Alert.vue?vue&type=script&lang=js"
export * from "./Alert.vue?vue&type=script&lang=js"
import style0 from "./Alert.vue?vue&type=style&index=0&id=19dce2bc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.10.0_vue-template-compiler@2.7.16_webpack@5.91.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19dce2bc",
  null
  
)

export default component.exports