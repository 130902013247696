<template>
     <div class="tshe_services">
    <div class="ts_bg meiqiaPanelBtn">
	<a href="tencent://AddContact/?fromId=50&fromSubId=1&subcmd=all&uin=2580996608">
      <div class="ts_head">
        <img class="ts_head_img" src="https://qiniu.staykoi.asia/%E4%BE%A7%E8%BE%B9/youcekefu1.png">
        <div class="ts_head_btn">在线客服</div>
      </div></a>
      <div class="ts_box">
        <div class="ts_white">
          <div class="ts_item ts_item1">
            <div class="ts_icon">
              <img class="ts_i_img ts_i_img1" src="https://qiniu.staykoi.asia/%E4%BE%A7%E8%BE%B9/youceweixintubiao.svg">
              <img class="ts_i_img ts_i_img2" src="https://qiniu.staykoi.asia/%E4%BE%A7%E8%BE%B9/weixinhuisetubiao.svg">
            </div>
            <div class="ts_h ts_large">加入官方群</div>
            <div class="ts_p ts_large">有幸遇见</div>
            <div class="ts_p ts_medium">加入群聊</div>
            <div class="ts_l"></div>
          </div>
          
  
  <div class="pcsp js_seowx_wrap"
   style="background-image: url('https://www.freeimg.cn/thumbnails/2920b5a9e9ece2267c77c56ff2b4b5c9.png'); background-size: cover; background-repeat: no-repeat;">
    <div style="display:none" class="pcsp_name js_seowx_name_wrap"><span class="js_seowx_name"></span></div>
    <a href="http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=2vU-UO_HfPri94NlYHjo2drOsLl9fCaR&authKey=j0NKDcV4skdDKmCOym%2FPOHzbmwR8%2FMZu3wZ0L9AYEGhC0VGdmnkjbcZqHEI1GoOr&noverify=0&group_code=783754655" target="_blank" class="pcsp_h" style="color: #FF1493; font-family: 'Arial', sans-serif; font-size: 16px; text-align: center; margin-bottom: 5px; background-color: rgba(255, 105, 180, 0.3); border-radius: 8px; padding: 8px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);">点击加入群聊</a>
    <div class="pcsp_p" style="color: #FF69B4; font-family: 'Comic Sans MS',font-weight:bold; cursive, sans-serif; font-size: 20px; text-align: center; margin-bottom: 10px; background-color: rgba(255, 182, 193, 0.3); border-radius: 10px; padding: 10px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">有幸遇见，恰巧合拍</div>
    <div class="pcsp_qrcode_wrap">
      <img class="pcsp_qrcode js_seowx_qrcode" src="https://qiniu.staykoi.asia/%E4%BE%A7%E8%BE%B9/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240412012010.png">
      <div class="pcsp_qrcode_move">
        <div class="pcspa pcspa1"></div>
        <div class="pcspa pcspa2"></div>
        <div class="pcspa pcspa3"></div>
        <div class="pcspa pcspa4"></div>
        <div class="pcspa_move"></div>
      </div>
    </div>
    <div class="pcsp_p" style="color: #FF69B4;font-weight:bold; font-family: 'Comic Sans MS', cursive, sans-serif; font-size: 20px; text-align: center; margin-bottom: 10px; background-color: rgba(255, 182, 193, 0.3); border-radius: 10px; padding: 10px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">QQ群：335891020</div>
  </div>
          <div class="ts_item ts_item2">
            <div class="ts_icon">
              <img class="ts_i_img ts_i_img1" src="https://qiniu.staykoi.asia/%E4%BE%A7%E8%BE%B9/1637557852075s3.svg">
              <img class="ts_i_img ts_i_img2" src="https://qiniu.staykoi.asia/%E4%BE%A7%E8%BE%B9/1637557852075s3_2.svg">
            </div>
            <div class="ts_h ts_large" >扫一扫</div>
            <div class="ts_p ts_large">添加Stay</div>
            <div class="ts_p ts_medium">扫一扫</div>
            <div class="ts_l"></div>
          </div>
          <div class="pcsp pcsp2" style="background-image: url('https://www.freeimg.cn/thumbnails/3f6d481a74f68088dfc1afb304d2d72e.png'); background-size: cover; background-repeat: no-repeat;">
            <div class="pcsp_qrcode_wrap" >
              <img class="pcsp_qrcode" src="https://qiniu.staykoi.asia/2024-2-10/%E5%8A%A8%E6%BC%AB/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240410214252.png">
              <div class="pcsp_qrcode_move">
                <div class="pcspa pcspa1"></div>
                <div class="pcspa pcspa2"></div>
                <div class="pcspa pcspa3"></div>
                <div class="pcspa pcspa4"></div>
                <div class="pcspa_move"></div>
              </div>
            </div>
            <div class="pcsp_p" style="color: #fff; font-family: 'Comic Sans MS', cursive, sans-serif; font-size: 20px; text-align: center; margin-bottom: 10px; background-color: rgba(255, 182, 193, 0.3); border-radius: 10px; padding: 10px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);font-weight:bold;">扫一扫，添加Stay微信</div>
          </div>
          <div class="ts_item ts_item3">
            <div class="ts_icon">
              <img class="ts_i_img ts_i_img1" src="https://qiniu.staykoi.asia/%E4%BE%A7%E8%BE%B9/youceshandongdianhua.svg">
              <img class="ts_i_img ts_i_img2" src="https://qiniu.staykoi.asia/%E4%BE%A7%E8%BE%B9/youcedianhuasuohuise.svg">
            </div>
            <div class="ts_h ts_large">开通会员</div>
            <div class="ts_p ts_large">免费下载资源</div>
            <div class="ts_p ts_medium">开通会员</div>
          </div>
          <div class="pcsp pcsp3" style="background-image: url('https://www.freeimg.cn/thumbnails/bde5699201b4a9a0aabc20f882b811cd.png'); background-size: cover; background-repeat: no-repeat;">
            <div class="pcsp_p2" style="color: #FF1493; font-family: 'Arial', sans-serif; font-size: 16px; text-align: center; margin-bottom: 5px; background-color: rgba(255, 105, 180, 0.3); border-radius: 8px; padding: 8px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"> 专属下载！   免费更新！<br> 优先售后！   全面服务！</div>
            <div class="pcsp_phone_wrap">
              <img class="pcsp_phone_img" src="https://qiniu.staykoi.asia/%E4%BE%A7%E8%BE%B9/youceshandongdianhua.svg">
            </div>
            <div class="pcsp_p" style="color: #FF1493; font-family: 'Arial', sans-serif; font-size: 16px; text-align: center; margin-bottom: 5px; background-color: rgba(255, 105, 180, 0.3); border-radius: 8px; padding: 8px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);">成为我们的VIP会员</div>
            <div class="pcsp_p" style="color: #FF1493; font-family: 'Arial', sans-serif; font-size: 16px; text-align: center; margin-bottom: 5px; background-color: rgba(255, 105, 180, 0.3); border-radius: 8px; padding: 8px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);">尊享无限免费下载使用</div>
            <a href="https://www.staykoi.asia" target="_blank" class="pcsp_h" style="color: #FF1493; font-family: 'Arial', sans-serif; font-size: 16px; text-align: center; margin-bottom: 5px; background-color: rgba(255, 105, 180, 0.3); border-radius: 8px; padding: 8px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"> [̲̅V̲̅I̲̅P̅]点击购买会员</a>
          </div>
        </div>
      </div>
    </div>
		<div class="ts_scroll_top visible" id="scroll_top">
      <img class="ts_st_line" src="https://qiniu.staykoi.asia/%E4%BE%A7%E8%BE%B9/youceguashengzi.svg">
      <div class="ts_st_btn" onclick="b2AsideBar.goTop()" id="scroll_top_clicker">
        <img class="ts_st_icon ts_st_icon1" src="https://qiniu.staykoi.asia/%E4%BE%A7%E8%BE%B9/youcetop.svg">
        <img class="ts_st_icon ts_st_icon2" src="https://qiniu.staykoi.asia/%E4%BE%A7%E8%BE%B9/youceguasuoliangse.svg">
      </div>
		</div>
     </div>
</template>

<script>
export default {
  name: 'TsServices',
  methods: {
    goTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>
<style scoped>
.aside-container { display: none; }
.tshe_services {
  position: fixed;
  bottom: 75px;
  right: 10px;
  z-index: 1000;
  cursor: pointer;
  color: #4a4a4a;
  font-size: 12px;
}
.ts_head {
  position: relative;
  margin-left: 5px;
  padding-top: 4px;
  width: 100px;
  height: 100px;
  text-align: center;
  border: 6px solid #ffc403;
  border-radius: 50%;
}
.ts_head:after {
  content: "";
  display: block;
  position: absolute;
  left: -11px;
  top: -11px;
  width: 110px;
  height: 110px;
  border: 2px solid #ffc403;
  border-radius: 50%;
  animation: tsheadscalel 1s .5s infinite;
}
.ts_head_img {
  width: 80px;
  border-radius: 50%;
  animation: tsheadscale 1s infinite;
}
@keyframes tsheadscale {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  to { transform: scale(1); }
}
@keyframes tsheadscalel {
  0% { opacity: 1; transform: scale(1); }
  50% { opacity: .5; transform: scale(1.1); }
  to { opacity: 1; transform: scale(1); }
}
.ts_head_btn {
  position: absolute;
  left: 1px;
  bottom: -15px;
  z-index: 3;
  margin: 0 auto;
  width: 90px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #ffdc00;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 700;
  box-shadow: 0 2px 5px rgba(78, 40, 5, .2);
  transition: background-color .3s;
}
.ts_head:hover .ts_head_btn {
  background-color: #ffc403;
}
/* 接下来是其他的样式，根据提供的内容继续添加 */
.ts_bg { z-index: 1; }
.ts_bg, .ts_box { position: relative; }
.ts_box {
  z-index: 2;
  padding: 5px 5px 30px;
  width: 110px;
  text-align: center;
  background-color: #ffc403;
  border-radius: 4px;
}
/* 其他样式继续在这里添加... */

/* 媒体查询示例 */
@media only screen and (max-width: 1440px) {
  .tshe_services { bottom: 50px; }
  /* 其他媒体查询样式... */
}

@media screen and (max-width: 768px){
  .tshe_services { display: none; }
}
.ts_white {
  position: relative;
  background-color: #fff;
  border-radius: 2px;
}
.ts_item {
  position: relative;
  padding-top: 14px;
  height: 105px;
}
.ts_icon {
  position: relative;
  margin: 0 auto;
  width: 40px;
  height: 30px;
}
.ts_item1 {
  padding-top: 28px;
  height: 120px;
}
.ts_i_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.ts_i_img2 { visibility: hidden; }
.ts_item:hover { background-color: #e3e3e3; }
.ts_item:hover .ts_i_img1 { visibility: hidden; }
.ts_item:hover .ts_i_img2 { visibility: visible; }
.ts_h {
  font-size: 14px;
  font-weight: 700;
}
.ts_h, .ts_p { margin-top: 5px; }
.ts_p { white-space: nowrap; }
.ts_l {
  position: absolute;
  left: 7px;
  bottom: 0;
  width: 86px;
  border-bottom: 1px dashed rgba(0, 0, 0, .2);
}
.ts_scroll_top {
  visibility: hidden;
  position: relative;
  z-index: 2;
}
.ts_scroll_top.visible { visibility: visible; }
.ts_st_line {
  top: -20px;
  height: 37px;
}
.ts_st_icon, .ts_st_line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.ts_st_icon { top: 15px; width: 50px; }
.ts_st_btn:hover .ts_st_icon1, .ts_st_icon2 { visibility: hidden; }
.ts_st_btn:hover .ts_st_icon2 { visibility: visible; }
.pcsp, .ts_medium { display: none; }
.pcsp {
  position: absolute;
  bottom: 0;
  left: -295px;
  padding-bottom: 33px;
  width: 277px;
  height: 315px;
  text-align: center;
  background: #fff;
  box-shadow: 8px 0 11px 0 rgba(0, 0, 0, .06), -8px 0 11px 0 rgba(0, 0, 0, .06);
  border-radius: 8px;
}
.pcsp:hover, .ts_item:hover+.pcsp { display: block; }
.pcsp:before {
  width: 20px;
  height: 100%;
  top: 0;
  right: -20px;
  background: transparent;
}
.pcsp:after, .pcsp:before {
  display: block;
  content: "";
  position: absolute;
}
.pcsp:after {
  top: 40px;
  right: -10px;
  width: 0;
  height: 0;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 10px solid #fff;
}
.pcsp2 { padding-top: 44px; }
.pcsp2:after { top: 140px; }
.pcsp3 { padding-top: 30px; }
.pcsp3:after { top: 240px; }
.pcsp_name {
  margin-bottom: 11px;
  color: #000;
  font-size: 16px;
  font-weight: 700;
}
.pcsp_h {
  padding-top: 26px;
  color: #000;
  font-size: 20px;
}
.pcsp_p {
  margin-top: 10px;
  color: #000;
  font-size: 14px;
}
.pcsp_p2 {
  padding-left: 40px;
  color: #4a4a4a;
  text-align: left;
  font-size: 14px;
  line-height: 24px;
}
.pcsp_qrcode_wrap {
  margin: 20px auto 17px;
}
.pcsp_qrcode, .pcsp_qrcode_wrap {
  position: relative;
  width: 148px;
  height: 148px;
}
.pcsp_qrcode_move {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 150px;
  height: 150px;
}
.pcspa {
  position: absolute;
  width: 9px;
  height: 9px;
  border-color: #ffdc00;
  border-width: 1px;
}
.pcspa1 { left: 0; border-left-style: solid; }
.pcspa2 { top: 0; border-top-style: solid; }
.pcspa2, .pcspa3 { right: 0; border-right-style: solid; }
.pcspa3 { left: 0; border-left-style: solid; }
.pcspa4 { bottom: 0; border-bottom-style: solid; }
.pcspa4, .pcspa3 { right: 0; border-right-style: solid; }
.pcspa_move {
  position: absolute;
  top: 0;
  left: 1px;
  width: 148px;
  height: 4px;
  background-image: url(https://qiniu.staykoi.asia/%E4%BE%A7%E8%BE%B9/scan-952a935d7b9d83abf4ca81393765792b.png);
  background-size: 148px 4px;
  animation: pcspaMove 2s linear infinite;
}
@keyframes pcspaMove {
  0% { top: 0; }
  to { top: 148px; }
}
.pcsp_phone_wrap { margin-top: 30px; }
.pcsp_phone_img { width: 74px; }

/* 其他样式继续在这里添加... */

/* 媒体查询示例 */
@media only screen and (max-width: 1440px) {
  .tshe_services { bottom: 50px; }
  /* 其他媒体查询样式... */
}

@media screen and (max-width: 768px){
  .tshe_services { display: none; }
}
</style>

 
